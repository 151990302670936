<template>
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <div class="card">
        <div class="card-body">
          <Form :validation-schema="schema" @submit="handleSubmit">
            <div class="fs-3 fw-bold">Biodata</div>
            <hr />
            <div class="row">
              <div class="col-3">
                <ImageUploader
                  :accept="'image/*;capture=camera'"
                  :uuid="file_photo"
                  @fileUploaded="handleFileUploaded"
                  :disabled="!editable"
                ></ImageUploader>
              </div>
              <div class="col">
                <FormRender
                  ref="formBiodata"
                  :initialForm="currentData"
                  :filtered="biodataSection"
                  :readonly="!editable"
                ></FormRender>
              </div>
            </div>

            <div class="fs-3 fw-bold">Sinyalemen</div>
            <hr />

            <FormRender
              ref="formSinyalement"
              :initialForm="currentData"
              :filtered="sinyalementSection"
              :readonly="!editable"
            ></FormRender>

            <hr />
            <!-- <div class="fs-3 fw-bold">Kehormatan</div> -->
            <template v-if="loadHonorComponent">
              <InputArray
                ref="honorsInput"
                label="Tanda Kehormatan"
                fieldname="honors"
                :preData="kehormatan"
                :disabled="!editable"
                :inputable="editable"
                @changeValue="hanldeChangeValue"
                @addInput="handleAddInput"
                @removeInput="handleRemoveInput"
              ></InputArray>
            </template>

            <hr />

            <div class="mb-10" v-if="false">
              <Field name="reason" as="select" class="form-control">
                <option value="">Pilih Alasan Pengajuan</option>
                <option value="1">Hilang</option>
                <option value="2">Rusak</option>
              </Field>
              <ErrorMessage name="reason" class="text-danger error-feedback" />
              <input type="file" name="" id="" class="form-control" />
            </div>

            <div class="d-flex justify-content-right mb-5">
              <a @click="$router.back()" class="btn btn-light btn-outline">
                Kembali
              </a>
              &nbsp; &nbsp;
              <div
                id="kt_ecommerce_edit_order_submit"
                class="btn btn-warning"
                @click="editable = !editable"
              >
                {{ editable ? "Batal" : "Ubah Data" }}
              </div>
              &nbsp; &nbsp;
              <button
                type="submit"
                id="kt_ecommerce_edit_order_submit"
                class="btn btn-success"
              >
                {{ editable ? "Simpan" : "Kirim Pengajuan" }}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import BaseService from "@/services/base.service";
import {
  inputType,
  parseLabel,
  hiddenInput,
  biodataSection,
  sinyalementSection,
} from "../../helpers/helper";
import FormRender from "../../components/forms/FormRender.vue";
import ImageUploader from "@/components/forms/ImageUploader.vue";
import InputArray from "@/components/forms/InputArray.vue";

// import SubmissionForm from "@/src/pages/kta/SubmissionForm";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    FormRender,
    ImageUploader,
    InputArray,
    // SubmissionForm,
  },
  data() {
    const schema = yup.object().shape({
      nama_lengkap: yup.string().required("Name is required!"),
      nip_nrp: yup.string().required("Nip/NRP is required!"),

      unity: yup.string().required("Kesatuan is required!"),
      dimension: yup.string().required("Matra is required!"),
      rank: yup.string().required("Pangkat is required!"),
      position: yup.string().required("Jabatan is required!"),

      tinggi: yup.number().required("Tinggi is required!"),
      berat: yup.number().required("Berat is required!"),
      rambut: yup.string().required("Rambut is required!"),
      mata: yup.string().required("Mata is required!"),
      gol_darah: yup.string().required("Darah is required!"),
      tempat_lahir: yup.string().required("Tempat Lahir is required!"),
      tanggal_lahir: yup.string().required("Tanggal Lahir is required!"),
      agama: yup.string().required("Agama is required!"),
      kontak_darurat: yup.string().required("Kontak is required!"),
      alamat: yup.string().required("Alamat is required!"),

      honors: yup.array().nullable(),
    });

    return {
      biodata: {},
      sinyalemen: {},
      kehormatan: [],
      matraOptions: [],
      pangkatOptions: [],
      jabatanOptions: [],
      kesatuanOptions: [],

      personData: null,
      editable: false,
      currentData: {},
      schema,
      hiddenInput,
      biodataSection,
      sinyalementSection,
      file_photo: null,
      loadHonorComponent: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    inputType,
    parseLabel,
    async init() {
      try {
        // const { token } = this.$route.params;
        const id = this.$route.params.personId;

        const matraService = new BaseService("master/matra");
        const pangkatService = new BaseService("master/pangkat");
        const personService = new BaseService("person");
        const jabatan = new BaseService("master/jabatan");
        const kesatuan = new BaseService("master/kesatuan");

        const masterData = [
          matraService.getListData(),
          pangkatService.getListData(),
          personService.getData(id),
          //   personService.getListData({
          //     unity: 1,
          //     rank: 1,
          //     position: 1,
          //     dimension: 1,
          //     honor: 1,
          //   }),
          jabatan.getListData(),
          kesatuan.getListData(),
        ];
        const [matraData, pangkatData, personData, jabatanData, kesatuanData] =
          await Promise.all(masterData);

        this.matraOptions = matraData.data.map((m) => {
          return {
            label: m.name,
            value: m.id,
          };
        });
        this.pangkatOptions = pangkatData.data.map((m) => {
          return {
            label: m.name,
            value: m.id,
          };
        });
        this.jabatanOptions = jabatanData.data.map((m) => {
          return { label: m.name, value: m.id };
        });
        this.kesatuanOptions = kesatuanData.data.map((m) => {
          return { label: m.name, value: m.id };
        });

        this.personData = toRaw(personData.data);
        // this.biodata = this.personData;
        // this.sinyalemen = this.personData;
        this.kehormatan = this.personData.honors.map((m) => m.name);

        /** // TODO: buat spt yg ada di halaman verifikasi data */
        const disableInput = ["id"];
        Object.entries(this.personData).forEach(([key, value]) => {
          if (!this.hiddenInput.includes(key)) {
            this.currentData[key] = {
              label: this.parseLabel(key),
              placeholder: value && value.name ? value.name : value,
              value: value && value.id ? value.id : value,
              options:
                value && value.name
                  ? [{ value: value.id, label: value.name }]
                  : [],
              type: this.inputType(key),
              disable: disableInput.includes(key),
            };
          }
        });
        this.currentData["dimension"]["options"] = this.matraOptions;
        this.currentData["position"]["options"] = this.jabatanOptions;
        this.currentData["rank"]["options"] = this.pangkatOptions;
        this.currentData["unity"]["options"] = this.kesatuanOptions;

        this.file_photo = this.personData.file_foto;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadHonorComponent = true;
      }
    },
    handleFileUploaded(value) {
      this.personData.file_foto = value.id;
    },
    hanldeChangeValue(value) {
      const data = toRaw(value);
      if (this.currentData.honors) {
        this.currentData.honors = data;
      }
    },
    handleAddInput() {
      this.kehormatan.push("");
    },
    handleRemoveInput(index) {
      this.kehormatan.splice(index, 1);
    },
    async handleSubmit() {
      if (this.editable) {
        const biodata = this.$refs.formBiodata.getUpdatedValue();
        const sinyalement = this.$refs.formSinyalement.getUpdatedValue();
        const honors = this.$refs.honorsInput.getArrayInput();
        const params = {
          ...biodata,
          ...sinyalement,
          file_foto: this.personData.file_foto,
          honors,
        };
        const Service = new BaseService("person");
        const { message } = await Service.updateData(
          this.personData.id,
          params
        );
        this.$swal.fire({
          icon: "success",
          title: "Success",
          message,
        });
        this.editable = false;
      } else {
        this.confirmAJukan(this.personData);
      }
    },
    async actionSubmitSubmission(data) {
      console.log(data);
    },
    async actionUpdatePersonalData(data) {
      console.log(data);
      const biodata = toRaw(this.biodata);
      const sinyalemen = toRaw(this.sinyalemen);
      const kehormatan = toRaw(this.kehormatan);

      const personParams = {
        nip_nrp: "",
        nama_lengkap: biodata.nama_lengkap,

        tinggi: sinyalemen.tinggi,
        berat: sinyalemen.berat,
        rambut: sinyalemen.rambut,
        mata: sinyalemen.mata,
        gol_darah: sinyalemen.gol_darah,
        tempat_lahir: sinyalemen.tempat_lahir,
        tanggal_lahir: sinyalemen.tanggal_lahir,
        agama: sinyalemen.agama,
        alamat: sinyalemen.alamat,
        kontak_darurat: sinyalemen.kontak_darurat,
        // nama_pasangan: "",
        // nama_ortu: "",
        // tempat_kawin: "",
        // no_surat_kawin: "",
        dimension_name: biodata.matra,
        position_name: biodata.pangkat,
        // rank_name: biodata.rank,
        unity_name: biodata.kesatuan,
        honors: kehormatan.map((k) => k.name),
      };

      const personService = new BaseService("person/services");

      await personService.createData(personParams);
    },
    async confirmAJukan(selectedData) {
      await this.$swal
        .fire({
          title: "Ajukan KTA",
          html: '<input ref="kta_number" name="kta_number" id="swal-input1" class="form form-control" placeholder="Nomor KTA">',
          // '<input ref="card_type_name" name="card_type_name" id="swal-input2" class="form form-control" placeholder="Jenis Kartu">',
          showCancelButton: true,
          confirmButtonText: "Ajukan",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !this.$swal.isLoading(),
          preConfirm: async () => {
            const kta_number = document.querySelector(
              "input[name=kta_number]"
            ).value;
            const card_type_name = "KTA";
            /*document.querySelector(
              "input[name=card_type_name]"
            ).value;*/
            if (!kta_number || !card_type_name) {
              this.$swal.showValidationMessage(
                "Nomor KTA dan Jenis KTA Wajib diisi!"
              );
            } else {
              try {
                const cardApplicationService = new BaseService(
                  "card-application"
                );
                const cardApplicationParam = {
                  kta_number: kta_number,
                  person_id: selectedData.id,
                  card_type_name: card_type_name,
                };
                return await cardApplicationService.createData(
                  cardApplicationParam
                );
              } catch (error) {
                console.log(error);
              }
            }
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            const { data, message } = result.value;
            console.log(data, message);
            this.$swal.fire({
              icon: "success",
              title: message,
              message: `Berhasil ${data.status} ID ${data.id}`,
            });
          }
        });
    },
  },
};
</script>
